import Notiflix from 'notiflix';

export const loaderStart = () => {
    Notiflix.Notify.Init({ width: '300px', fontSize: '14px', timeout: 4000, messageMaxLength: 200, });
    Notiflix.Loading.Standard('Loading...',{ svgColor:"#f7941e"});
}

export const loaderEnd = () => {
    Notiflix.Loading.Remove()
}

//RealTime Notifications
export const notification = (status, msg) => {
    if (status) {
        Notiflix.Loading.Remove()
        Notiflix.Notify.Success(msg)
    }
    else {
        Notiflix.Loading.Remove()
        Notiflix.Notify.Failure(msg)
    }

}
